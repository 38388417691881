/*Issue fixed for issue #24,issue #66 */
import React, { useState, useEffect } from "react";
import {
  makeStyles,
  createStyles,
  Theme,
  // useTheme,
  createMuiTheme,
} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Login from "components/login/Login";
import SignUp from "components/signup/SignUp";
import SignUpStepTwo from "components/signup/SignUpStepTwo";
import LinkDevice from "components/signup/LinkDevice";
import ForgotPassword from "components/forgot-password/ForgotPassword";
import homeIcon from "assets/bt-logins/login_home_w.svg";
import { LoginContainerProps } from "./types";
import loginBG from "assets/bt-logins/login_BG.svg";
import companyLogo from "custom/companyLogo.png";//imported rulo logo for issue #66
import phoneIcon from "assets/bt-logins/login_phone_w.svg";
import emailIcon from "assets/bt-logins/login_email_w.svg";
import PasswordRequired from "components/password-required/PasswordRequired";
import clsx from "clsx";
import SetNewPassword from "components/set-new-password/SetNewPassword";
import { Colors } from "common/Colors";
import { companyAddress, companyCityStateZip, companyEmail, companyLogoHeight, companyLogoWidth, companyName, companyPhone, footerText } from "custom/customizations";

const LoginContainer: React.FC<LoginContainerProps> = ({
  authState,
  ...props
}: LoginContainerProps) => {
  const classes = useStyles();
  const theme = createMuiTheme({
    breakpoints: {
      values: {
        xs: 600,
        sm: 600,
        md: 960,
        lg: 1920,
        xl: 2560,
      },
    },
  });

  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const [logEvent, setLogEvent] = useState("");
  const [cognitoUser, setCognitoUser] = useState({});

  const getLogType = (logEventType: string): void => {
    setLogEvent(logEventType);
  };

  const getCognitoUser = (cognitoUser: any) => {
    setCognitoUser(cognitoUser);
  };

  const displayScreenType = (logEvent: string) => {
    switch (logEvent) {
      case "logIn":
        return (
          <Login
            setLogType={getLogType}
            setCognitoUserObj={getCognitoUser}
            {...props}
          />
        );
      case "forgotPassword":
        return <ForgotPassword setLogType={getLogType} {...props} />;
      case "setNewPassword":
        return <SetNewPassword setLogType={getLogType} {...props} />;
      case "signUp":
        return <SignUp setLogType={getLogType} {...props} />;
      case "createAccountStepTwo":
        return <SignUpStepTwo setLogType={getLogType} {...props} />;
      case "linkDeviceId":
        return <LinkDevice setLogType={getLogType} {...props} />;
      case "newPasswordRequired":
        return (
          <PasswordRequired userObj={cognitoUser} setLogType={getLogType} />
        );
      default:
        break;
    }
  };

  useEffect(() => {
    setLogEvent("logIn");
  }, [matches]);

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        {/* adding css class for styling login screen in responsive UI issue #24 */}
        <Grid item xs={4} className={classes.loginscreen}>
          <div className={classes.leftContainer}>
            <div className={classes.companyInfo}>
              <div
                className={clsx({
                  [classes.companyQuoteLG]: matches,
                  [classes.companyQuoteXL]: !matches,
                })}
              >
                <div>
                  {/* changed classname to companyLogo and src to RulotechLogo and added height and width for issue #66 */}
                  <img
                    className={clsx({ [classes.companyLogo]: !matches })}
                    src={companyLogo}
                    alt=""
                    width={companyLogoWidth}
                    height={companyLogoHeight}
                  />
                </div>
                {/* removed the line take full control or your wellsite operations for issue #66*/}
              </div>
              <div
                className={clsx({
                  [classes.addressLG]: matches,
                  [classes.addressXL]: !matches,
                })}
              >
                <img
                  className={classes.addressIcons}
                  src={homeIcon}
                  alt=""
                  width={matches ? 15 : 18}
                />{" "}
                {/* changed from Basintrak to Rulo Technologies for issue #66 */}
                &nbsp; {companyName}
                <br />
                {/* Changed address for issue #66 */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{companyAddress}<br />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{companyCityStateZip}
                <br /> <br />
                <img
                  className={classes.addressIcons}
                  src={phoneIcon}
                  alt="Company address"
                  width={matches ? 15 : 18}
                />{" "}
                {/* Changed phone number for issue #66 */}
                &nbsp; {companyPhone}
                <br /> <br />
                <img
                  className={classes.addressIcons}
                  src={emailIcon}
                  alt="Company email address"
                  width={matches ? 15 : 18}
                />{" "}
                {/* Changed email id for issue #66 */}
                &nbsp; {companyEmail}
              </div>
              <div className={classes.imageContainer}>
                <img
                  style={{ width: "70%" }}
                  src={loginBG}
                  alt="Background image for login page"
                />
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.formContainer} item xs={8}>
          {displayScreenType(logEvent)}
        </Grid>
      </Grid>
      {/* Added footer with css class for issue #66 */}
      <div className={classes.footerdiv}>
        <p className={classes.footertext}>{footerText}</p>
      </div>
    </div>
  );
};

export default LoginContainer;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      height: "100vh",
      margin: 0,
    },
    leftContainer: {
      padding: "2rem 0 1rem 2rem",
      // zIndex: -1,
    },
    companyInfo: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: Colors.MainColor,//color changed to slate for issue #66
      color: "#fff",
      borderRadius: "10px",
      height: "94vh",
    },
    companyLogoLG: {
      position: "relative",
      top: "150px",
      left: "122px",
      width: "226px",
      height: "42px",
    },
    companyLogoXL: {
      position: "relative",
      top: "200px",
      left: "190px",
      width: "226px",
      height: "42px",
    },
    //changed classname form basinTrakLogo to companyLogo
    companyLogo: {
      height: "50px",
    },
    companyQuoteLG: {
      position: "relative",
      top: "20%",
      fontSize: "2em",
      fontFamily: "Squada One, cursive !important",
    },
    companyQuoteXL: {
      position: "relative",
      top: "20%",
      fontSize: "2.5em",
      fontFamily: "Squada One, cursive !important",
    },
    addressLG: {
      position: "relative",
      top: "60%",
      right: "13%",
      fontSize: "1em",
      zIndex: 2,
    },
    addressXL: {
      position: "relative",
      top: "60%",
      right: "13%",
      fontSize: "1.5em",
      zIndex: 2,
    },
    addressIcons: {
      position: "relative",
      top: "1px",
    },
    loginBGLG: {
      position: "relative",
      width: "450px",
      top: "320px",
      left: "170px",
      zIndex: 1,
    },
    loginBGXL: {
      position: "relative",
      width: "580px",
      top: "378px",
      left: "241px",
      zIndex: 1,
    },
    formContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      //adding media query to align login screen in responsive UI issue #24
      [`@media (max-width: 800px)`]: {
        paddingTop: "10em",
      },
    },
    imageContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      height: "100%",
      width: "100%",
    },

    //adding media query for login screen in responsive UI issue #24
    loginscreen: {
      [`@media (max-width: 800px)`]: {
        display: "none",
      },
    },
    //adding css class for styling footer div for issue #66
    footerdiv: {
      marginLeft: "64rem",
      marginTop: "-55px",
      [`@media (max-width: 700px)`]: {
        marginLeft: "65px",
        marginTop: "165px",
      },
      [`@media (min-width:701px) and (max-width: 800px)`]: {
        marginLeft: "195px",
        marginTop: "400px",
      },
      [`@media (min-width:801px) and (max-width: 1279px)`]: {
        marginLeft: "46rem",
        marginTop: "-55px",
      },
    },
    //adding css class for styling footer text for issue #66
    footertext: {
      fontSize: "14px",
    },

  })
);
