// Use this to customize the app.  This should be different for every prod app
export const footerText = "© 2024 Rulo LLC.";
export const companyName = "Rulo LLC";
export const companyAddress = "7906 N Sam Houston Parkway W, Suite 200";
export const companyCityStateZip = "Houston, TX 77064";
export const companyPhone = "720-379-1656";
export const companyEmail = "info@rulotech.com";
export const companyLogoWidth = "250px";
export const companyLogoHeight = "78px";
export const colorPowered = "#ffff";
export const companyDomain = ".rulotech.com";
export const ifSRC = "https://auth.rulotech.com/";
export const companyDarkColor = "#253746";
export const custAppId = "1";